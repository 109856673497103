<template>
  <div class="c-video u-pb--3">
    <div class="c-video__wrapper u-pb--3">
      <div v-if="video" class="c-video__wrapper u-mb">
        <VVideoPlayer
          v-if="video.file"
          ref="player"
          :video-url="video.file.url"
          :video-type="video.file.mime"
          :captions="captions"
          :video-attributes="videoAttributes"
          :options="options"
          :chapters="chapters"
          @timeupdate="onTimeUpdate"
          @texttracksloaded="onTextTrackLoad"
        />
      </div>
      <div class="c-video-player__chapters u-mb">
        <VVideoChapter
          v-for="chapter in chapters"
          :key="chapter.name"
          :title="chapter.name"
          :start="chapter.start_time"
          :end="chapter.end_time"
          :currentTime="currentTime"
          @click.native="updateCurrentTime(chapter.start)"
        />
      </div>
      <div v-if="video.description" class="c-video__description u-mb" v-md-html="video.description" />
      <FHButton v-if="video.videoText"
        defaultStyles
        @click.native="showTranscript = !showTranscript">{{ $t('VIDEO_TRANSCRIPT')}}</FHButton>
      <div v-show="showTranscript" class="c-video__video-text" v-md-html="video.videoText" />
    </div>
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import VVideoPlayer from '@forlagshuset/v-video-player-vjs'
import VVideoChapter from '@forlagshuset/v-video-chapter'
import FHButton from '@forlagshuset/v-button'

export default {
  name: 'Video',

  components: {
    VVideoPlayer,
    VVideoChapter,
    FHButton
  },

  props: {
    content: Object
  },

  methods: {
    autoClosePIPWindow() {
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture()
      }
    },
  },

  created(){
      document.addEventListener("visibilitychange", () => {
        if (document.visibilityState == "visible") return 
        this.autoClosePIPWindow()    
      })
  },

  setup(props, { refs }) {
    const video = computed(() => props.content.video_type)
    const showTranscript = ref(false)
    const currentTime = ref(0)
    const player = refs.player
    const captions = computed(() => video.closedCaption || [])
    const chapters = computed(() => video.mediaChapter)
    const videoAttributes = ref({
      crossorigin: '',
      playsinline: '',
      controls: '',
      poster: ''
    })
    const options = {
      playbackRates: [0.5, 1, 1.5, 2],
      language: 'nb',
      skipTimeForward: 15,
      skipTimeBackward: 15
    }

    watch(video, val => {
      if (val.cover) {
        videoAttributes.value.poster = val.cover.image_type.file.url
      }
    })

    const onTimeUpdate = time => {
      currentTime.value = time
    }

    const onTextTrackLoad = tracks => {
      this.textTracks = tracks
      this.currentTextTrack = this.textTracks.filter(track => track.language === 'nb')[0] // Look for current language in app
    }
    
    const updateCurrentTime = time => {
      player.player.currentTime(time)
      player.player.play()
    }

    return {
      captions,
      chapters,
      video,
      options,
      currentTime,
      videoAttributes,
      onTextTrackLoad,
      onTimeUpdate,
      updateCurrentTime,
      showTranscript
    }
  }
}
</script>

<style lang="scss">
.c-video__wrapper {
  width: $medium;
  max-width: 100%;
  margin: 0 auto;
}
</style>
