<template>
  <div class="c-text-element">
    <VHeading v-if="content.title"
      header="h3"
      class="c-text-element__title"
      :text="content.title">
    </VHeading>
    <div class="c-text-box__body"
      v-md-html="content.body" />
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'

export default {
  components: {
    VHeading
  },

  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-text-box__body {
  max-width: 800px;
  img {
    max-width: 100%;
  }
  p:first-of-type {
    .c-article__wrapper & {
      margin-top: 0;
    }
  }
}
</style>