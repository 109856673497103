<template>
  <div class="c-section">
    <VHeading header="h3">
      {{ content.name }}
    </VHeading>
    <component v-for="item in content.items"
      class="c-section__item"
      :key="item._id"
      :is="item.__typename"
      :content="item" />
  </div>
</template>

<script>
import VHeading from '@forlagshuset/v-heading'
import ComponentLearningElementTextBox from '@/components/LearningElements/TextBox'
import ComponentLearningElementTextElement from '@/components/LearningElements/TextElement'
import ComponentLearningElementArticleElement from '@/components/LearningElements/ArticleElement'

export default {
  components: {
    VHeading,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementArticleElement,
  },
  props: {
    content: Object
  }
}
</script>