<template>
  <div class="c-text-box" v-md-html="content.content" />
</template>

<script>
export default {
  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-text-box {
  max-width: 800px;
}
</style>