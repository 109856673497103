<template>
  <FHButton
    class="c-dbok u-mb"
    defaultStyles
    :href="`https://dur.dbok.no/#/book/${content.bookId}/${content.startPage}`"
    target="_blank"
    rel="noopener noreferrer"
  >
    <div class="c-dbok__title">
      <DbokIcon class="c-dbok__title-icon" />
      {{ title }}
    </div>
    <div class="c-dbok__desc" v-md-html="content.description" />
  </FHButton>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import FHButton from '@forlagshuset/v-button'
import DbokIcon from '@/assets/svg/Icon_Text'

export default {
  components: {
    FHButton,
    DbokIcon,
  },

  props: {
    content: Object,
  },

  setup(props) {
    const title = ref('')

    onMounted(async () => {
      const bookData = await (
        await fetch(
          'https://booksync.fagbokforlaget.no/api/pub/' + props.content.bookId,
        )
      ).json()
      title.value = bookData.title
    })

    return {
      title,
    }
  },
}
</script>

<style lang="scss">
.c-dbok {
  display: block;
  background-color: transparent;
  border: none;
  padding: 0rem;
}
.c-dbok__desc {
  p {
    margin-bottom: 0;
  }
}
.c-dbok__title-icon {
  vertical-align: sub;
  height: 1.25rem;
}
</style>
